/* Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: #2e2e2e #00000000;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 8px;
}

*::-webkit-scrollbar-track {
    background: #00000000;
}

*::-webkit-scrollbar-thumb {
    background-color: #9494949b;
    border-radius: 10px;
}

kbd {
    border-radius: 3px;
    border: 1px solid #e6e6e6;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    color: #333;
    font-size: 0.8em;
    font-weight: 700;
    padding: 2px 4px;
    white-space: nowrap;
}
